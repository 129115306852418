<template>
  <div class="signin">
    <v-container>
      <v-row justify="center">
        <v-col cols="sm-6 md-5" style="max-width: 500px">
          <v-card class="mx-auto" id="forgot_password_card">
            <v-container>
              <v-card-text>
                <v-row justify="start">
                  <v-col cols="auto">
                    <img
                      height="32px"
                      src="/images/logo/ec-up_setLR_BIG_trans.gif"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p class="display-1 text--primary">
                      パスワードをお忘れの方
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  ><v-col cols="12">
                    <p>
                      メールアドレスにパスワードリセット用のリンクを送信します。
                    </p>
                    <p class="error--text">
                      ※パートナー企業さま経由でお申込みのショップさまは、大変恐れ入りますがパートナー企業さまへ直接ご連絡ください。
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-form ref="form" v-model="valid_email">
                      <v-text-field
                        v-model="mailaddress"
                        type="email"
                        label="ログインメールアドレス"
                        validate-on-blur
                        outlined
                        hide-details
                        :rules="[rules.email]"
                      ></v-text-field>
                    </v-form>
                  </v-col>
                  <p v-if="error" class="errors error--text">{{ error }}</p>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12">
                    <v-btn
                      @click="submit"
                      exactid="sign_in_button"
                      color="primary"
                      :disabled="!valid_email"
                      block
                      x-large
                      class="title"
                      >メールを送信
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-container>
          </v-card>

          <v-row justify="end" class="mt-3">
            <v-col cols="auto">
              <router-link
                :to="{ name: 'SignIn' }"
                class="text-decoration-underline"
              >
                ログイン
              </router-link>
            </v-col>
            <v-col cols="auto">
              <router-link
                :to="{ name: 'SignUp' }"
                class="text-decoration-underline"
              >
                新規登録
              </router-link>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
  data: () => ({
    mailaddress: null,
    error: "",
    valid_email: false,
    loader: null,
    loading: false,
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "メールアドレスが間違っています";
      },
    },
  }),
  methods: {
    async submit() {
      this.loading = true;

      var actionCodeSettings = {
        url: "https://ec-up.app/#/sign_in",
      };

      const auth = getAuth();
      await sendPasswordResetEmail(auth, this.mailaddress, actionCodeSettings)
        .then(() => {
          alert(
            "メールを送信しました。メールに含まれるパスワード変更用リンクから操作を続けてください。"
          );
          this.$router.push({ name: "SignIn" });
        })
        .catch((error) => {
          if (error.code == "auth/user-not-found") {
            this.error =
              "登録されていないメールアドレスです。再度ご確認ください。";
          }
        });

      this.loading = false;
    },
  },
};
</script>

<style scoped>
#forgot_password_card {
  margin-top: 50px;
  margin-bottom: 10px;
}
</style>
