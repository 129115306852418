<template>
  <div class="card-content">
    <div class="text-center py-4">
      <div class="">
        おまかせ機能：
        <v-chip
          small
          :ripple="false"
          :color="isFullAutoOn ? 'blue lighten-4' : 'secondary'"
          :text-color="isFullAutoOn ? 'info' : ''"
          class="text-body-1 font-weight-bold"
          >{{ isFullAutoOn ? "ON" : "OFF" }}</v-chip
        >
      </div>
    </div>
    <div class="d-flex pt-1 pb-12">
      <div
        class="d-flex flex-column justify-center align-center"
        style="width: 35%; max-width: 256px"
      >
        <v-progress-circular
          :rotate="-90"
          :size="120"
          :width="16"
          :value="getPercentage(number_of_running_items, number_of_items)"
          :color="mainColor"
          class="mb-6"
        >
          <span class="text-h5 black--text">
            {{ getPercentage(number_of_running_items, number_of_items) }}%
          </span>
        </v-progress-circular>

        <div class="mb-8 text-center">
          <div class="text-body-2 mb-2">
            <v-icon :color="mainColor" class="mr-1"
              >mdi-checkbox-marked-circle</v-icon
            >
            <span class="text-body-2">稼働中</span>
          </div>
          <div class="text-h5">
            {{ Number(number_of_running_items).toLocaleString() }} /
            {{ Number(number_of_items).toLocaleString() }}
          </div>
        </div>

        <div>
          <div class="mb-1">
            <v-icon color="grey" class="mr-1">mdi-check-circle-outline</v-icon>
            <span class="text-body-2"
              >未稼働：{{
                Number(number_of_not_running_items).toLocaleString()
              }}
              商品</span
            >
          </div>
          <div class="mb-4">
            <v-icon color="error" class="mr-1">mdi-alert-circle</v-icon>
            <span class="text-body-2"
              >エラー：{{
                Number(number_of_error_items).toLocaleString()
              }}
              商品</span
            >
          </div>
          <div class="text-center">
            <v-chip
              v-if="number_of_error_items > 0"
              small
              color="error"
              @click="gotoItemPage"
              >エラー商品を確認する</v-chip
            >
            <v-chip v-else small color="secondary">エラー商品を確認する</v-chip>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-center flex-grow-1 pr-8 pl-2">
        <div v-for="(tool, i) in tools" :key="i" class="mb-4">
          <div class="d-flex text-body-2 grey--text text--darken-2 mb-1">
            <div class="d-flex align-center">
              <v-img
                class="mr-2"
                max-width="24"
                :src="`/images/dashboard/${tool.name_en}_grey.png`"
              ></v-img>
              <span class="">{{ tool.name }}</span>
            </div>
            <div class="d-flex flex-grow-1 justify-end align-center">
              <span class=""
                >{{
                  Number(getNumberOfRunningTool(tool.name)).toLocaleString()
                }}商品</span
              >
              <span class="ml-2"
                >({{
                  getPercentage(
                    getNumberOfRunningTool(tool.name),
                    number_of_items
                  )
                }}%)</span
              >
            </div>
          </div>
          <v-progress-linear
            :color="isInactive ? 'grey' : mainColor"
            :value="
              getPercentage(getNumberOfRunningTool(tool.name), number_of_items)
            "
          ></v-progress-linear>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "OperationItemCardMain",

  props: {
    descriptionKey: { class: String, required: true },
    fullAutoColumnName: { class: String, required: true },
    mainColor: { class: String, required: true },
    isInactive: { class: Boolean, required: true },
  },

  data: () => ({
    number_of_items: 0,
    number_of_running_items: 0,
    //稼働状況
    number_of_not_running_items: 0,
    number_of_error_items: 0,
    // システム稼働数
    tools: [],
    number_of_items_group_by_tool: [],
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },
    isFullAutoOn() {
      return this.user?.[this.fullAutoColumnName] || false;
    },
  },

  watch: {
    user(newVal) {
      if (newVal) {
        this.setEcupStatusStats();
      }
    },
  },

  created() {
    axios
      .get("/api/v1/tools", {
        params: { target: `description${this.descriptionKey}` },
      })
      .then((response) => {
        this.tools = response.data.tools;
      });

    if (this.isInactive) return;

    this.setEcupStatusStats();
  },

  methods: {
    setEcupStatusStats() {
      axios
        .get("/api/v1/items/ecup_status_stats", {
          params: { code: this.descriptionKey },
        })
        .then((result) => {
          this.number_of_items = result.data.number_of_items;
          this.number_of_running_items = result.data.number_of_running_items;
          this.number_of_not_running_items =
            result.data.number_of_not_running_items;
          this.number_of_error_items = result.data.number_of_error_items;
          this.number_of_items_group_by_tool =
            result.data.number_of_items_group_by_tool;
        });
    },
    getNumberOfRunningTool(toolName) {
      const toolData = this.number_of_items_group_by_tool.find(
        (data) => data.name === toolName
      );

      return toolData ? toolData.number_of_running_tool : "0";
    },
    getPercentage(num1, num2) {
      if (num2 === 0) return 0;
      return Math.floor((num1 / num2) * 100);
    },
    gotoItemPage() {
      this.$router.push({
        name: `Item${this.descriptionKey}`,
        query: { only_error: true },
      });
    },
  },
};
</script>
