<template>
  <ecup-modal
    title="オプション機能 追加/変更"
    @close_this_modal="$emit('close_this_modal')"
    modal_width="850px"
    v-if="options"
  >
    <Show
      v-if="selectedOption"
      :user="user"
      :isAdminShop="isAdminShop"
      :option="selectedOption"
      @fetchUser="fetchUser()"
      @setUser="setUser($event)"
      @close_this_modal="selectedOptionCode = null"
      @closeAllModal="$emit('close_this_modal')"
    />

    <!-- index -->
    <v-row justify="center" class="mt-5" v-else>
      <v-col v-for="(option, i) in options" :key="i">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              outlined
              v-bind="!isEnablePlan(option) ? attrs : null"
              v-on="!isEnablePlan(option) ? on : null"
            >
              <v-list-item
                active-class="success--text"
                class="cursor-pointer"
                :input-value="option.is_enabled"
                :ripple="isEnablePlan(option)"
                @click="selectOptionCode(option)"
              >
                <v-list-item-icon>
                  <v-icon x-large :color="option.is_enabled | colorIsEnabled()">
                    {{ option.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ option.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ option.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  {{ option.is_enabled | formatIsEnabled() }}
                </v-list-item-action>
              </v-list-item>
            </v-sheet>
          </template>
          <span>担当販売店までご連絡ください</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <!-- /index -->
  </ecup-modal>
</template>

<script>
import axios from "axios";
import deepmerge from "deepmerge";
import timeUtilsMixin from "../../packs/mixins/time_utils";
import userUtilsMixin from "../../packs/mixins/user_utils";
import EcupModal from "../components/EcupModal.vue";
import Show from "./Show.vue";

export default {
  components: {
    EcupModal,
    Show,
  },

  mixins: [timeUtilsMixin, userUtilsMixin],

  props: {
    user: {
      type: Object,
      default: {},
    },

    isAdminShop: {
      type: Boolean,
      default: false,
    },

    specifiedOptionCode: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      masterOptions: [],
      addedOptions: [
        {
          code: "for_pc",
          icon: "mdi-desktop-mac",
          icon_color: "success",
          description:
            "PC用商品説明文とPC用販売説明文、それぞれにシステムをつけることができるようになります。",
          settingExplanation:
            "▼スライド画像で機能の詳細についてご確認いただけます。",
          osusumeRating: 5,
          uriageRating: 3,
          sakugenRating: 4,
          procedure_images: [
            {
              src: "/images/options/for_pc/01.gif",
            },
            {
              src: "/images/options/for_pc/02.png",
            },
            {
              src: "/images/options/for_pc/03.png",
            },
            {
              src: "/images/options/for_pc/04.png",
            },
            {
              src: "/images/options/for_pc/05.png",
            },
            {
              src: "/images/options/for_pc/06.gif",
            },
            {
              src: "/images/options/for_pc/07.png",
            },
          ],
        },
        {
          code: "summary_mail",
          icon: "mdi-chart-timeline-variant-shimmer",
          icon_color: "success",
          description: "売上や転換率などの情報を1日1回メールでお知らせします。",
          settingExplanation:
            "▼スライド画像で機能の詳細についてご確認いただけます。",
          osusumeRating: 5,
          uriageRating: 2,
          sakugenRating: 1,
          procedure_images: [
            {
              src: "/images/options/summary_mail/01.gif",
            },
            {
              src: "/images/options/summary_mail/02.png",
            },
            {
              src: "/images/options/summary_mail/03.png",
            },
            {
              src: "/images/options/summary_mail/04.png",
            },
            {
              src: "/images/options/summary_mail/05.png",
            },
            {
              src: "/images/options/summary_mail/06.png",
            },
          ],
        },
        {
          code: "shop_top",
          icon: "mdi-storefront-outline",
          icon_color: "#AC6A00",
          description:
            "新店舗トップページの余っているパーツを利用してシステムをつけることができるようになります。",
          settingExplanation:
            "▼スライド画像で機能の詳細についてご確認いただけます。",
          osusumeRating: 3,
          uriageRating: 2,
          sakugenRating: 2,
          procedure_images: [
            {
              src: "/images/options/shop_top/01.gif",
            },
            {
              src: "/images/options/shop_top/02.png",
            },
            {
              src: "/images/options/shop_top/03.png",
            },
            {
              src: "/images/options/shop_top/04.png",
            },
            {
              src: "/images/options/shop_top/05.png",
            },
            {
              src: "/images/options/shop_top/06.png",
            },
          ],
        },
      ],
      selectedOptionCode: null,
    };
  },

  computed: {
    options() {
      let url = this.isAdminShop
        ? "/api/v1/options/admin_index"
        : "/api/v1/options";

      if (!Object.keys(this.masterOptions).length) {
        const params = { shop_code: this.user.shop_code };
        if (this.isAgencyChild) {
          params.include_all_plan = 1;
        }

        axios
          .get(url, { params })
          .then((res) => {
            this.masterOptions = res.data.options;
          });
      }

      if (Object.keys(this.masterOptions).length) {
        const options = this.masterOptions
          .map((masterOption) => {
            const addedOption = this.addedOptions.find(
              (x) => x.code === masterOption.code
            );
            if (addedOption) {
              let option = deepmerge(masterOption, addedOption);

              const shopsOption = this.user.shops_options.find(
                (x) => x.option.code === masterOption.code
              );

              option = deepmerge(option, {
                is_enabled:
                  shopsOption && shopsOption.is_enabled === true
                    ? shopsOption.is_enabled
                    : false,
                note: this.getNote(shopsOption),
              });
              option["disabled"] = shopsOption.status === "disabled";

              return option;
            }
          })
          .filter(Boolean);

        return options;
      }
    },

    selectedOption() {
      return this.options.find((x) => x.code === this.selectedOptionCode);
    },

    isAgencyChild() {
      return this.user['is_agency_child?'];
    }
  },

  created() {
    this.selectedOptionCode = this.specifiedOptionCode;
  },

  filters: {
    formatIsEnabled(value) {
      return value === true ? "ON" : "OFF";
    },
    colorIsEnabled(value) {
      return value === true ? "success" : "grey";
    },
  },

  methods: {
    getNote(shopsOption) {
      if (!shopsOption) {
        return "";
      }

      switch (shopsOption.status) {
        case "disabled":
          return "現在のプランではご利用いただけません。";
        case "pending":
          return "利用設定を完了させてください。";
        default:
          break;
      }

      // 切り替え制限
      if (this.isAdminShop) {
        if (
          shopsOption["switched_within_month?"] &&
          shopsOption.is_enabled === true &&
          shopsOption.amount > 0
        ) {
          return "オプションのOFFは1ヶ月に1回までです。";
        }
      } else {
        if (
          shopsOption["switched_today?"] &&
          shopsOption.is_enabled === true &&
          shopsOption.amount > 0
        ) {
          return "オプションの切り替えは1日1回までです。";
        }
      }

      return "";
    },

    fetchUser() {
      if (this.isAdminShop) {
        this.$emit("fetchUser", this.user.shop_code);
      } else {
        this.fetch_user();
      }
    },

    setUser(data) {
      if (this.isAdminShop) {
        this.$emit("setUser", data);
      } else {
        this.SET_USER(data);
      }
    },

    selectOptionCode(option) {
      if (!this.isEnablePlan(option)) return;
      this.selectedOptionCode = option.code;
    },

    isEnablePlan(option)  {
      if (!this.isAgencyChild) return true;
      if (this.isAdminShop) return true;
      return !!option.plans_options.find(
        (item) =>item.plan && item.plan.id === this.user.plan.id && item.amount === 0
      );
    }
  },
};
</script>
