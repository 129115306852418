<template>
  <ecup-modal
    :title="
      user.plan.code == 'trial'
        ? 'プラン変更：トライアル期間中はゴールドプランと同等の内容をご利用いただいておりました'
        : 'プラン変更'
    "
    @close_this_modal="$emit('close_this_modal')"
    modal_width="680px"
  >
    <v-container>
      <Plans class="mb-6" />
      <ChangeToFreeButton v-if="freeAvailable" />
      <DetailButton />
      <FAQButton />
    </v-container>
  </ecup-modal>
</template>

<script>
import EcupModal from "../components/EcupModal.vue";
import Plans from "./PlanIndexModal/Plans.vue";
import ChangeToFreeButton from "./PlanIndexModal/ChangeToFreeButton.vue";
import DetailButton from "./PlanIndexModal/DetailButton.vue";
import FAQButton from "./PlanIndexModal/FAQButton.vue";

export default {
  name: "PlanIndexModal",

  components: {
    EcupModal,
    Plans,
    ChangeToFreeButton,
    DetailButton,
    FAQButton,
  },

  data() {
    return {
      max_num_of_items_of_free: 20000,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    isPlatinum() {
      return this.max_num_of_items_of_free < this.user.items_count;
    },

    freeAvailable() {
      return (
        this.user.plan.code !== "free" &&
        this.max_num_of_items_of_free >= this.user.items_count
      );
    },
  },
};
</script>
