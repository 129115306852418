<template>
  <v-card rounded="xl" :class="isInactive ? 'inactive-card' : 'active-card'">
    <v-card-title class="text-body-1">
      <div class="d-flex align-center">
        <v-icon size="40" :color="mainColor" class="mr-4">
          mdi-email-outline
        </v-icon>
        <span>メール</span>
      </div>
    </v-card-title>
    <v-divider />

    <v-card-text class="card-content px-16 py-8">
      <template v-if="!isShowOptionButton">
        <div class="d-flex text-body-2 grey--text text--darken-2 mb-4">
          <div class="d-flex align-center">
            <v-icon color="grey" class="mr-2">
              mdi-chart-timeline-variant-shimmer
            </v-icon>
            <span class="">サマリーメール</span>
          </div>
          <div class="d-flex flex-grow-1 justify-end align-center">
            <span class="">
              {{ isEnableSummaryMail ? "送信中" : "未送信" }}
            </span>
          </div>
        </div>
        <v-progress-linear
          :color="isEnableSummaryMail ? mainColor : 'grey'"
          :value="isEnableSummaryMail ? 100 : 0"
        ></v-progress-linear>
      </template>
      <template v-else>
        <div class="d-flex justify-center">
          <v-btn
            color="rgb(235, 90, 0)"
            class="px-16"
            dark
            large
            @click="showOptionIndexModal = true"
          >
            <span class="text-h6 font-weight-bold"
              >サマリーメールを無料で利用する</span
            >
          </v-btn>
        </div>
      </template>
    </v-card-text>

    <InactiveLayer v-if="isInactive" />

    <OptionIndexModal
      v-if="showOptionIndexModal"
      specified-option-code="summary_mail"
      :user="user"
      @close_this_modal="showOptionIndexModal = false"
    />
  </v-card>
</template>

<script>
import InactiveLayer from "./InactiveLayer.vue";
import OptionIndexModal from "../../option/OptionIndexModal.vue";
export default {
  name: "MailCard",
  components: {
    InactiveLayer,
    OptionIndexModal,
  },

  data: () => ({
    mainColor: "#6F2975",
    showOptionIndexModal: false,
  }),

  computed: {
    user() {
      return this.$store.state.user;
    },
    isInactive() {
      return this.$store.getters.isFreePlan;
    },
    isEnableSummaryMail() {
      return this.$store.getters.isEnableSummaryMail;
    },
    isShowOptionButton() {
      return !this.isInactive && !this.isEnableSummaryMail;
    },
  },
};
</script>
