<template>
  <v-row
    justify="center"
    style="min-height: 50px"
    v-if="selectedOptionCodes.length > 0"
  >
    <v-col cols="auto" class="pt-0">
      <v-checkbox
        v-for="plans_option in options"
        v-model="selected"
        :key="plans_option.option.code"
        :label="`${
          plans_option.option.name
        } +${plans_option.amount.toLocaleString()}円`"
        :value="plans_option.option.code"
        :disabled="
          user.plan.code == plan.code ||
          plans_option.option.configuration_needed
        "
        dense
        hide-details
        @change="changeSelectedOptionCodes($event)"
      >
      </v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    options: Array,
    user: Object,
    plan: Object,
    selectedOptionCodes: Array,
  },

  data() {
    return {
      selected: [...this.selectedOptionCodes],
    };
  },

  methods: {
    changeSelectedOptionCodes(selected) {
      this.$emit("change-selected-option-codes", selected); // 中間に通知
    },
  },
};
</script>
