<template>
  <v-container fluid class="pa-6" v-if="user">
    <v-row v-if="user.shop_page_common_auth_failed">
      <v-col>
        <ec-up-alert mode="error" class="body-2 mx-3">
          <span>
            コンテンツページ自動更新のご利用に必要なAPI項目が許可されていません。ダッシュボードよりライセンスキーを変更してください。
          </span>
        </ec-up-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="display-1">
        <v-icon left large color="#004986"> mdi-clipboard-outline </v-icon>
        {{ name }} ページ一覧
      </v-col>
    </v-row>

    <Main
      @fetchUser="fetchUser"
      @setUser="setUser"
      :name="name"
      :user="user"
      :headers="headers"
    />

    <Guard
      v-if="showGuard"
      @showPlanIndexModal="showPlanIndexModal = true"
      :user="user"
    />

    <PlanIndexModal
      v-if="showPlanIndexModal"
      @close_this_modal="showPlanIndexModal = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";

import Main from "./components/Main.vue";
import Guard from "./components/Guard.vue";
import PlanIndexModal from "../plan/PlanIndexModal.vue";
import userUtilsMixin from "../../packs/mixins/user_utils";
import EcUpAlert from "../components/alert/EcUpAlert.vue";

export default {
  name: "ShopPageLayouts",
  components: { Main, Guard, PlanIndexModal, EcUpAlert },
  mixins: [userUtilsMixin],
  computed: {
    user() {
      return this.$store.state.user;
    },
    showGuard() {
      return this.user && !this.user.is_contents_update_enabled;
    },
  },
  data() {
    return {
      name: "コンテンツページ",
      showPlanIndexModal: false,
      headers: {
        title: {
          name: "ページタイトル<br />URL",
          description: "",
          width: "54%",
          sortable: true,
          class: "bg-gray justify-start px-0",
        },
        status: {
          name: "URL<br />アクセス",
          description: "",
          width: "9%",
          sortable: true,
          class: "bg-gray justify-start px-0",
        },
        remaining_small_image_widget_count: {
          name: "残小画像<br />パーツ数",
          description: "",
          width: "9%",
          sortable: true,
          class: "bg-gray px-0",
          style: "font-size: 10px;"
        },
        remaining_large_image_widget_count: {
          name: "残大画像<br />パーツ数",
          description: "",
          width: "9%",
          sortable: true,
          class: "bg-gray px-0",
          style: "font-size: 10px;"
        },
        remaining_item_widget_count: {
          name: "残商品<br />パーツ数",
          description: "",
          width: "9%",
          sortable: true,
          class: "bg-gray px-0",
          style: "font-size: 10px;"
        },
        updateTime: {
          name: "更新日時",
          description: "",
          width: "10%",
          sortable: true,
          class: "bg-gray px-0",
        },
      },
    };
  },
  async created() {
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.fetch_user();
    },
    setUser(user) {
      this.SET_USER(user);
    },
  },
};
</script>
