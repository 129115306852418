<template>
  <transition name="modal" v-if="is_render">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <v-container class="pa-5">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="11">
                <div class="text-h5">システム設定</div>
              </v-col>
              <div>
                <v-icon @click="$emit('close')" class="modal_close_icon"
                  >mdi-close-box</v-icon
                >
              </div>
            </v-row>

            <operation-selection-status
              :total_items_count="total_items"
              :tool_name="selected_tool && selected_tool['name']"
              :tool_description="selected_tool && selected_tool['description']"
              :is_all_tools="delete_request_data.is_all_tools"
              :checked_items_count="checked.length"
              :editing_target_is_all_items="editing_target_is_all_items"
              :operation="operation"
            />

            <div class="modal-body my-3 px-4">
              <operation-selection
                v-if="render_now == 'selection_of_operation'"
                :operation="operation"
                @selected="
                  operation = $event;
                  render_now = 'selection_of_system';
                "
              />

              <div v-if="operation == 'bundle_create'">
                <system-selection
                  v-if="render_now == 'selection_of_system'"
                  :shops_tools="user.shops_tools"
                  :tools="tools"
                  :allow_all_item="false"
                  @selected="
                    create_request_data['operation_shops_tool_id'] = $event;
                    addingToolSelected(
                      getToolByShopsToolId(tools, user.shops_tools, $event)
                    );
                    check_same_create_job_exists();
                    render_now = 'selection_of_design';
                  "
                />

                <design-selection
                  v-if="
                    render_now == 'selection_of_design' &&
                    !is_same_create_job_exists
                  "
                  :layouts="selected_tool['layouts']"
                  :selected_layout="selected_layout"
                  :position="create_request_data['position_in_description']"
                  :errorMessage="errorMessageLayout"
                  :element="element"
                  @layout_selected="
                    create_request_data['operation_layout_id'] = $event;
                    layoutChoosed();
                  "
                  @position_selected="
                    create_request_data['position_in_description'] = $event
                  "
                />

                <v-alert
                  outlined
                  type="success"
                  text
                  v-if="
                    render_now == 'selection_of_design' &&
                    is_same_create_job_exists
                  "
                >
                  <template #prepend>
                    <v-icon
                      large
                      mdi
                      color="primary"
                      class="item_sync_icon mr-5"
                      >mdi-autorenew</v-icon
                    >
                  </template>
                  <div class="text-body-2">
                    <div>現在、同じシステムを表示する処理を実行中です。</div>
                    <div>処理の完了までしばらくお待ちください。</div>
                  </div>
                </v-alert>

                <v-row justify="space-around">
                  <v-col cols="2">
                    <return-btn @clicked="return_to_before_step"></return-btn>
                  </v-col>
                  <v-col
                    cols="10"
                    v-if="
                      render_now == 'selection_of_design' &&
                      create_request_data.operation_layout_id &&
                      !is_same_create_job_exists
                    "
                  >
                    <v-btn
                      x-large
                      block
                      color="primary"
                      @click="createRequestFinished"
                      :disabled="updateDisabled"
                    >
                      <div class="text-h6 font-weight-bold">更新する</div>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    render_now == 'selection_of_design' &&
                    isCrowded() &&
                    !is_same_create_job_exists
                  "
                  justify="center"
                >
                  <span class="red--text"
                    >現在混み合っています。更新の完了までに数時間かかることがあります。</span
                  >
                </v-row>
              </div>

              <div v-if="operation == 'bundle_delete'">
                <div v-if="render_now == 'selection_of_system'">
                  <system-selection
                    :shops_tools="user.shops_tools"
                    :tools="tools"
                    :allow_all_item="true"
                    @return_to_before_step="
                      render_now = 'selection_of_operation'
                    "
                    @selected="
                      delete_request_data.is_all_tools = false;
                      deletingToolSelected($event);
                      render_now = 'confirm_to_delete';
                    "
                    @selected_all_items="
                      delete_request_data.job_target_items_tools_attributes =
                        [];
                      selected_deleting_tool_id = null;
                      selected_deleting_tool = null;
                      delete_request_data.is_all_tools = true;
                      check_same_delete_job_exists();
                      render_now = 'confirm_to_delete';
                    "
                  />
                </div>

                <div v-if="render_now == 'confirm_to_delete'">
                  <v-row justify="center" v-if="!is_same_delete_job_exists">
                    <v-col cols="1">
                      <v-icon size="50" color="error">mdi-alert</v-icon>
                    </v-col>
                    <v-col cols="7">
                      <div>選択したシステムを削除してもよろしいですか？</div>
                      <div>[更新する]ボタンを押すと即時更新が行われます。</div>
                    </v-col>
                  </v-row>
                  <v-alert
                    outlined
                    type="success"
                    text
                    v-if="is_same_delete_job_exists"
                  >
                    <template #prepend>
                      <v-icon
                        large
                        mdi
                        color="primary"
                        class="item_sync_icon mr-5"
                        >mdi-autorenew</v-icon
                      >
                    </template>
                    <div class="text-body-2">
                      <div>現在、同じシステムを取り外す処理を実行中です。</div>
                      <div>処理の完了までしばらくお待ちください。</div>
                    </div>
                  </v-alert>
                </div>

                <v-row justify="space-around">
                  <v-col cols="2">
                    <return-btn @clicked="return_to_before_step"></return-btn>
                  </v-col>
                  <v-col
                    cols="10"
                    v-if="
                      render_now == 'confirm_to_delete' &&
                      !is_same_delete_job_exists
                    "
                  >
                    <v-btn
                      x-large
                      block
                      color="primary"
                      @click="deleteRequestFinished"
                    >
                      <div class="text-h6 font-weight-bold">更新する</div>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    isCrowded() &&
                    render_now == 'confirm_to_delete' &&
                    !is_same_delete_job_exists
                  "
                  justify="center"
                >
                  <span class="red--text"
                    >現在混み合っています。更新の完了までに数時間かかることがあります。</span
                  >
                </v-row>
              </div>
            </div>
          </v-container>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import SystemSelect from "./SystemSelect.vue";
import OperationSelectionStatus from "./SystemEditModal/OperationSelectionStatus.vue";
import OperationSelection from "./SystemEditModal/OperationSelection.vue";
import SystemSelection from "./SystemEditModal/SystemSelection.vue";
import DesignSelection from "./SystemEditModal/DesignSelection.vue";
import ReturnBtn from "./SystemEditModal/ReturnBtn.vue";

import ToolUtilsMixin from "../../../packs/mixins/tool_utils";

export default {
  name: "system-edit-modal",
  mixins: [ToolUtilsMixin],
  props: [
    "checked",
    "is_render",
    "tools",
    "total_items",
    "editing_target_is_all_items",
    "default_operation",
    "default_tool",
    "default_rendering",
    "unfinished_jobs",
    "element",
  ],
  components: {
    SystemSelect,
    OperationSelectionStatus,
    OperationSelection,
    SystemSelection,
    OperationSelection,
    DesignSelection,
    ReturnBtn,
  },
  watch: {
    is_render: function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.render_now = "selection_of_operation";
        if (this.default_operation) {
          this.operation = this.default_operation;
        } else {
          this.operation = null;
        }
        this.refreshRequestData();
        this.create_request_data["is_all_items"] =
          this.editing_target_is_all_items;
        if (!this.editing_target_is_all_items) {
          this.create_request_data.job_target_items_attributes =
            this.items_for_job_request;
        }

        this.delete_request_data["is_all_items"] =
          this.editing_target_is_all_items;
        if (!this.editing_target_is_all_items) {
          this.delete_request_data.job_target_items_attributes =
            this.items_for_job_request;
        }

        if (this.default_tool) {
          this.fillDefaultSelectedValue(this.default_tool);
        } else {
          this.selected_tool = null;
          this.selected_deleting_tool_id = null;
          this.selected_layout = null;
          this.selected_deleting_tool = null;
        }
        if (this.default_rendering) {
          this.render_now = this.default_rendering;
        }
      }
    },
    selected_layout(val) {
      if (val === null) {
        return;
      }

      if (val.is_premium && !this.isAvailablePremium) {
        this.updateDisabled = true;
        this.errorMessageLayout = "ゴールド以上のプランでご利用可能です。";
      } else {
        this.updateDisabled = false;
        this.errorMessageLayout = null;
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    items_for_job_request() {
      return this.checked.map(function (item_code) {
        return { item_code: item_code };
      });
    },
    isAvailablePremium() {
      return this.user["is_available_premium?"];
    },
  },
  data() {
    return {
      updateDisabled: false,
      errorMessageLayout: null,
      render_now: "selection_of_operation",
      operation: null,
      selected_tool: null,
      is_same_delete_job_exists: false,
      is_same_create_job_exists: false,
      selected_layout: null,
      create_request_data: {},
      create_request_data_sample: {
        operation: "bundle_create",
        target_element: this.element,
        operation_shops_tool_id: null,
        operation_layout_id: null,
        is_all_items: false,
        position_in_description: "bottom",
        job_target_items_attributes: [],
      },
      delete_request_data: {},
      selected_deleting_tool_id: null,
      selected_deleting_tool: null,
      delete_request_data_sample: {
        operation: "bundle_delete",
        target_element: this.element,
        is_all_items: false,
        is_all_tools: false,
        job_target_items_attributes: [],
        job_target_items_tools_attributes: [],
      },
    };
  },
  methods: {
    return_to_before_step() {
      switch (this.render_now) {
        case "selection_of_system":
          this.render_now = "selection_of_operation";
          break;
        case "selection_of_design":
          this.selected_tool = null;
          this.render_now = "selection_of_system";
          break;
        case "confirm_to_delete":
          this.selected_tool = null;
          this.render_now = "selection_of_system";
          break;
      }
    },
    isCrowded() {
      return this.unfinished_jobs.length > 1;
    },
    addingToolSelected(tool) {
      this.selected_tool = tool;
      this.selected_layout = tool.layouts[0];
      this["create_request_data"]["operation_layout_id"] = tool.layouts[0].id;
    },
    async deletingToolSelected(shops_tool_id) {
      let tool = this.getToolByShopsToolId(
        this.tools,
        this.user.shops_tools,
        shops_tool_id
      );
      this.selected_tool = tool;
      if (!this.delete_request_data.is_all_tools) {
        this.delete_request_data.job_target_items_tools_attributes = [
          {
            shops_tool_id: shops_tool_id,
          },
        ];
      }
      this.check_same_delete_job_exists();
    },
    layoutChoosed() {
      let selected_layout = this.selected_tool.layouts.find(
        (element) =>
          element.id == this.create_request_data["operation_layout_id"]
      );
      this.selected_layout = selected_layout;
    },
    refreshRequestData() {
      this.create_request_data = _.cloneDeep(this.create_request_data_sample);
      this.delete_request_data = _.cloneDeep(this.delete_request_data_sample);
    },
    fillDefaultSelectedValue(tool) {
      let s_t = this.user.shops_tools.find((shops_tool) => {
        return shops_tool.tool_id === tool.id;
      });
      this.create_request_data.operation_shops_tool_id = s_t.id;
      this.selected_deleting_tool_id = s_t.id;
      this.selected_tool = tool;

      this.selected_layout = this.selected_tool.layouts[0];
      this.create_request_data.operation_layout_id =
        this.selected_tool.layouts[0].id;

      if (!this.delete_request_data.is_all_tools) {
        this.delete_request_data.job_target_items_tools_attributes = [
          {
            shops_tool_id: s_t.id,
          },
        ];
      }
    },
    async creatingJobRequest() {
      let job = await axios.post("/api/v1/jobs", this.create_request_data);
      return job.data;
    },
    async deletingJobRequest() {
      let job = await axios.post("/api/v1/jobs", this.delete_request_data);
    },
    async createRequestFinished() {
      await this.creatingJobRequest();
      this.$emit("request_finished");
    },

    async deleteRequestFinished() {
      await this.deletingJobRequest();
      this.$emit("request_finished");
    },

    check_same_create_job_exists() {
      this.is_same_create_job_exists = this.unfinished_jobs.some(
        (existing_job) => {
          return (
            existing_job.is_all_items ==
              this.create_request_data.is_all_items &&
            existing_job.operation == this.create_request_data.operation &&
            existing_job.operation_shops_tool_id ==
              this.create_request_data.operation_shops_tool_id
          );
        }
      );
    },

    check_same_delete_job_exists() {
      this.is_same_delete_job_exists = this.unfinished_jobs.some(
        (existing_job) => {
          return (
            existing_job.is_all_items ==
              this.delete_request_data.is_all_items &&
            existing_job.is_all_tools ==
              this.delete_request_data.is_all_tools &&
            existing_job.operation == this.delete_request_data.operation &&
            JSON.stringify(
              existing_job.job_target_items_tools.map((tool) => {
                return { shops_tool_id: tool.shops_tool_id };
              })
            ) ==
              JSON.stringify(
                this.delete_request_data.job_target_items_tools_attributes
              )
          );
        }
      );
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 730px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
  height: auto;
  max-height: 88vh;
  vertical-align: middle;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

/*
       * The following styles are auto-applied to elements with
       * transition="modal" when their visibility is toggled
       * by Vue.js.
       *
       * You can easily play with the modal transition by editing
       * these styles.
       */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#rakuten_api_access_permit_carousel {
  border: 1px solid;
}
</style>
