<template>
  <v-row justify="center">
    <v-col
      cols="6"
      v-for="(plan, i) in plans"
      :key="i"
      class="plan_card_in_modal"
      :class="plan.code"
    >
      <plan-features-card
        :plan="plan"
        :percentOff="percentOff"
        :selectedOptionCodes="selectedOptionCodes"
        customClass="background-text"
        @change-selected-option-codes="changeSelectedOptionCodes($event)"
      >
        <template #btn>
          <Btn :plan="plan" @push-change-page="pushChangePage" />

          <v-row>
            <v-divider></v-divider>
          </v-row>
        </template>
      </plan-features-card>
    </v-col>
  </v-row>
</template>

<script>
import PlanFeaturesCard from "../../components/PlanFeaturesCard.vue";
import Btn from "./Cards/Btn.vue";

export default {
  name: "Cards",

  components: {
    PlanFeaturesCard,
    Btn,
  },

  props: ["plans", "selectedOptionCodes", "isPlatinum"],

  computed: {
    user() {
      return this.$store.state.user;
    },

    percentOff() {
      return this.isPlatinum
        ? 0
        : 0 < this.user.my_available_off_50_coupons_count
        ? 50
        : 0 < this.user.my_available_off_10_coupons_count
        ? 10
        : 0;
    },
  },

  methods: {
    changeSelectedOptionCodes(selected) {
      this.$emit("change-selected-option-codes", selected); // 中間に通知
    },

    pushChangePage(plan) {
      this.$router.push({
        name: plan.route_name,
        query: { selectedOptionCodes: this.selectedOptionCodes },
      });
    },
  },
};
</script>

<style scoped>
.plan_card_in_modal {
  border: solid 2px #cccccc;
  padding: 7px;
  margin: -1px;
}

.plan_card_in_modal.gold_trial {
  border: solid 10px #ffd700;
  padding: 0px;
  margin: -2px;
}
</style>
