<template>
  <transition name="modal" v-if="isRender">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <v-container class="pa-5">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="11">
                <div class="text-h5">システム設定</div>
              </v-col>
              <div>
                <v-icon @click="$emit('close')" class="modal_close_icon"
                  >mdi-close-box</v-icon
                >
              </div>
            </v-row>

            <div class="mt-5 px-4">
              <v-row>
                <v-col cols="12" class="my-2" style="background-color: #eeeeee">
                  <div class="text-body-2 ml-5">
                    <span>選択ページ：</span>
                    <span class="contents-page-color"
                      >{{ shopPageLayouts.length }}ページ</span
                    >

                    <template v-if="operation">
                      <span> ｜　処理：</span>
                      <span class="contents-page-color">{{
                        operation.label
                      }}</span>
                    </template>

                    <template v-if="shopPageSystem">
                      <span> ｜　システム：</span>
                      <span class="contents-page-color">{{
                        shopPageSystem.name
                      }}</span>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div v-if="!operation" class="my-6">
              <v-radio-group
                v-model="operation"
                mandatory
                hide-details
              >
                <div class="d-flex justify-space-around">
                  <v-radio :value="null" style="display: none" />
                  <label v-for="(operation, i) in operations" :key="i" class="cursor-pointer">
                    <v-radio :value="operation" style="display: none;" />
                    <div
                      class="d-flex flex-column justify-center px-6 py-2"
                      style="
                        border: 4px solid rgb(158, 158, 158);
                        border-radius: 8px;
                      "
                    >
                      <v-icon size="48px" color="grey" class="my-2">{{ operation.icon }}</v-icon>
                      <div class="grey--text my-1">{{ operation.label }}</div>
                    </div>
                  </label>
                </div>
              </v-radio-group>
            </div>

            <div v-if="operation && !shopPageSystem" class="mt-5 px-4">
              <v-row>
                <v-col cols="6" v-for="(system, i) in shopPageSystems" :key="i">
                  <v-btn
                    x-large
                    outlined
                    tile
                    block
                    color="#004986"
                    style="border-width: 2px;"
                    @click="shopPageSystem = system"
                  >
                    <span style="color:black;">{{ system.name }}</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="operation.key==='remove'" justify="center">
                <v-col cols="10">
                  <v-btn
                    x-large
                    outlined
                    tile
                    block
                    color="#004986"
                    style="border-width: 2px;"
                    @click="shopPageSystem = { code: 'all', name: '全システム' }"
                  >
                    <span style="color:black;">全システム</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="pa-6 text-center">
                <v-col>
                  <v-btn
                    x-large
                    class="grey white--text font-weight-bold"
                    @click="operation = null"
                    >戻る</v-btn
                  >
                </v-col>
              </v-row>
            </div>

            <div v-if="shopPageSystem && operation.key==='remove'" class="modal-body mt-3">
              <v-row class="pa-6">
                <v-col>
                  <div class="d-flex align-center justify-center">
                    <div class="mr-4">
                      <v-icon x-large color="red">mdi-alert</v-icon>
                    </div>
                    <div>
                      選択したシステムを削除してもよろしいですか？<br />
                      [更新する]ボタンを押すと即時更新が行われます。
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="pa-6">
                <v-col>
                  <div class="d-flex">
                    <v-btn
                      x-large
                      class="flex-grow-0 grey white--text font-weight-bold"
                      @click="shopPageSystem = null"
                      >戻る</v-btn
                    >
                    <v-btn
                      x-large
                      color="primary"
                      class="flex-grow-1 ml-4 text-h6 font-weight-bold"
                      @click="removeShopPageSystem"
                      >更新する</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="shopPageSystem" class="modal-body my-3 px-4">
              <design-selection
                :is-multiple="true"
                :shop-page-layouts="shopPageLayouts"
                :shop-page-system="shopPageSystem"
                @close="shopPageSystem = null"
                @update="updateShopPageLayout"
              />
            </div>
          </v-container>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import OperationSelectionStatus from "./SystemEditModal/OperationSelectionStatus.vue";
import DesignSelection from "./SystemEditModal/DesignSelection.vue";

import ToolUtilsMixin from "../../../packs/mixins/tool_utils";

export default {
  name: "multiple-edit-modal",
  mixins: [ToolUtilsMixin],
  props: {
    isRender: {
      type: Boolean,
      default: false
    },
    shopPageLayouts: { type: Array },
    shopPageSystems: { type: Array }
  },
  components: {
    OperationSelectionStatus,
    DesignSelection
  },
  watch: {},
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  data() {
    return {
      operations: [
        { key: 'remove', label: '取り外す', icon: 'mdi-minus-circle' },
        { key: 'create', label: '表示する', icon: 'mdi-plus-circle' }
      ],
      operation: null,
      shopPageSystem: null
    };
  },
  methods: {
    async updateShopPageLayout(params) {
      for (let i = 0; i < params.length; i++) {
        const param = params[i];
        param.operation = param.shop_page_part_id ? 'update_part' : 'create_part';
        await this.creatingJobRequest(param);
      }

      this.save();
    },
    async removeShopPageSystem() {
      for (let i = 0; i < this.shopPageLayouts.length; i++) {
        const layout = this.shopPageLayouts[i];

        let target = null;
        if (this.shopPageSystem.code !== 'all') {
          const parts = await this.getParts(layout.id);
          target = parts.find(
            (part) => part.shop_page_system_id === this.shopPageSystem.id
          );

          if (!target) continue;
        }

        const params = {
          operation: 'delete_part',
          shop_page_part_id: target ? target.id : null,
          shop_page_layout_id: layout.id,
          shop_page_system_id: this.shopPageSystem.code === 'all' ? null : this.shopPageSystem.id
        };

        await this.creatingJobRequest(params);
      }

      this.save();
    },
    async creatingJobRequest(params) {
      params.status = 'wating';

      const job = await axios.post("/api/v1/shop_page_jobs", params);
      return job.data;
    },
    async getParts(shopPageLayoutId) {
      const params = {
        shop_page_layout_id: shopPageLayoutId,
      };

      const response = await axios.get("/api/v1/shop_page_layouts/get_parts", {
        params,
      });

      return response.data.parts;
    },
    save() {
      this.operation = null;
      this.shopPageSystem = null;
      this.$emit('save');
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 730px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
  height: auto;
  max-height: 88vh;
  vertical-align: middle;
}
</style>
