<template>
  <div>
    <v-row>
      <v-col class="body-2">
        【初めての方】商品にシステムをつける方法はこちら⇒
        <a @click="showProcedureOneItem()"> 「１商品に表示する/はずす方法」 </a>
        <a @click="showProcedureMultipleItems()">
          「複数商品に表示する/はずす方法」 </a
        ><br />
        不具合・要望・感想は画面右下の「お問い合わせ」より送信くださいませ。
      </v-col>
    </v-row>

    <div v-if="user.items_count > maxNumberOfItems">
      <v-row justify="center">
        <v-col cols="12">
          <v-alert type="warning" border="left" icon="mdi-alert" text>
            <v-row>
              <v-col>
                <span class="body-1">
                  商品数が50万以上のため、商品一覧を表示できません。
                </span>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-row>
        <v-col cols="12">
          <v-card class="pa-5">
            <v-card-text>
              <table-filters
                :onlyError="onlyError"
                :onlyChecked="onlyChecked"
                :shops_tools="user.shops_tools"
                :tools="tools"
                :search_shops_tool_id="search_shops_tool_id"
                :search="search"
                :filter="filter"
                :status="status"
                @update:onlyerror="
                  onlyError = $event;
                  searchItems();
                "
                @update:onlychecked="
                  onlyChecked = $event;
                  searchItems();
                "
                @update:search_shops_tool_id="
                  search_shops_tool_id = $event;
                  searchItems();
                "
                @update:search="
                  search = $event;
                  searchItems();
                "
                @update:filter="
                  filter = $event;
                  searchItems();
                "
                @update:status="
                  status = $event;
                  searchItems();
                "
              ></table-filters>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="end">
        <v-col cols="auto">
          <full-auto-config-card
            :is_full_auto_on="user[columnNameFullAuto]"
            :name="name"
            :imgUrl="omakaseImgUrl"
            v-if="user.plan.code != 'free'"
            @updateFullAuto="updateFullAuto"
          ></full-auto-config-card>
        </v-col>
      </v-row>

      <div v-if="showTable">
        <edit-button
          :is_full_auto_on="user[columnNameFullAuto]"
          @checkedItemEditButtonClicked="checkedItemEditButtonClicked()"
          @allItemEditButtonClicked="allItemEditButtonClicked()"
        />

        <v-row justify="center">
          <v-col cols="auto">
            <v-pagination
              v-model="page"
              :length="totalPages"
              @input="changePage()"
              :total-visible="totalVisible"
              circle
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
            ></v-pagination>
          </v-col>
        </v-row>

        <table-status
          :checked="checked"
          :pagePers="pagePers"
          :page="page"
          :totalPages="totalPages"
          :total="items.length"
          :totalHits="totalHits"
          :itemsPerPage="itemsPerPage"
          @uncheck_all="uncheckAll()"
          @update:itemsPerPage="
            itemsPerPage = $event;
            fetchItems();
          "
        />

        <v-row no-gutters id="tableArea" style="position: relative">
          <v-col cols="6">
            <v-simple-table
              class="scroll_item"
              style="border: solid 0px #f7f7f7 !important"
            >
              <item-table-head
                :checkedAll="checkedAll"
                :headers="headers"
                :order="order"
                :direction="direction"
                :tools="tools_on_table"
                :columnNameFullAuto="columnNameFullAuto"
                @update:checkedAll="
                  checkedAll = $event;
                  checkAll();
                "
                @sort="sortItems($event)"
              />

              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  height="80px"
                  :class="{
                    'red lighten-5': item.errors.length,
                  }"
                >
                  <td class="text-center" style="min-width: 64px">
                    <v-checkbox
                      v-model="checked"
                      :value="item.item_code"
                      v-if="
                        !user[columnNameFullAuto] || !item[columnNameFullAuto]
                      "
                    ></v-checkbox>
                  </td>

                  <td class="text-left px-0" width="50%">
                    <v-list-item
                      class="px-1"
                      style="width: 175px"
                      target="_blank"
                    >
                      <v-badge
                        :value="item.errors.length"
                        color="error"
                        icon="mdi-exclamation-thick"
                        bordered
                        overlap
                      >
                        <v-tooltip
                          left
                          color="error"
                          :disabled="item.errors.length === 0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item-avatar
                              tile
                              size="50"
                              class="mr-2"
                              v-on="on"
                              v-bind="attrs"
                            >
                              <v-img
                                height="50px"
                                width="50px"
                                :src="item.imageUrl + '?_ex=50x50'"
                                aspect-ratio="1"
                                class="grey lighten-2"
                              ></v-img>
                            </v-list-item-avatar>
                          </template>

                          <span v-for="error in item.errors" :key="error.id">
                            {{ error }}<br />
                          </span>
                        </v-tooltip>
                      </v-badge>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item-content
                            style="max-width: 130px"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-list-item-title
                              class="blue--text text--darken-3 text-decoration-underline"
                              style="font-size: 0.9rem"
                            >
                              <a :href="rakutenPageLink(item)" target="_blank">
                                {{ item.itemName.slice(0, 10) }}
                              </a>
                            </v-list-item-title>
                            <v-list-item-subtitle
                              v-html="item.item_code"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                        <span class="tooltip_item_name">{{
                          item.itemName
                        }}</span>
                      </v-tooltip>
                    </v-list-item>
                  </td>

                  <td class="text-center px-0" width="30%">
                    {{ remainingByteSize(item).toLocaleString() }}
                  </td>
                  <td
                    class="text-center px-0"
                    width="30%"
                    v-if="targetElement === 'descriptionForSmartPhone'"
                  >
                    {{ item.remaining_number_of_images }}
                  </td>
                  <td class="text-center px-0" width="30%" v-else>
                    {{ item.itemLayout }}
                  </td>
                  <td
                    v-if="user[columnNameFullAuto] && user.plan.code != 'free'"
                    class="px-2"
                  >
                    <v-switch
                      color="primary"
                      class="mt-0 pt-0"
                      value
                      input-value="true"
                      dense
                      v-model="item[columnNameFullAuto]"
                      hide-details
                      @change="updateItemUseOsusumeConf(item.id, $event)"
                    ></v-switch>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>

          <v-col cols="6" style="position: relative">
            <v-simple-table
              id="tableSlider"
              class="scroll_item"
              style="position: relative; border: solid 0px #f7f7f7 !important"
            >
              <thead>
                <tr height="90px" class="bg-gray">
                  <th
                    class="bg-gray text-center item_table_nallow_th px-2"
                    v-for="tool in selectedSystemId
                      ? [tools.find((data) => data.id === selectedSystemId)]
                      : tools"
                    :key="tool.id"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on"
                          ><img
                            class="d-inline-block mb-1"
                            :src="'/images/items/' + tool.name_en + '.png'"
                            alt=""
                            width="48px"
                        /></span>
                      </template>
                      {{ tool.name }}システムの稼働状況
                    </v-tooltip>
                    <template v-if="tool.name_short">{{
                      tool.name_short
                    }}</template>
                    <template v-else>{{ tool.name }}</template>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in items" :key="item.id" height="80px">
                  <td
                    :class="{
                      'text-center': true,
                      ecup_system_td: true,
                      full_auto_on:
                        user[columnNameFullAuto] && item[columnNameFullAuto],
                    }"
                    v-for="tool in tools_on_table"
                    :key="tool.id"
                    style="
                      background-color: #f2f6e5;
                      border-left: 1px solid rgba(0, 0, 0, 0.12);
                    "
                  >
                    <v-tooltip
                      bottom
                      v-show="item.tools[tool.name_en] == 'setting_up'"
                      content-class="caption"
                    >
                      <template v-slot:activator="{ on, attrs1 }">
                        <v-icon
                          large
                          mdi
                          v-bind="attrs1"
                          v-on="on"
                          color="primary"
                          class="item_sync_icon"
                          v-show="item.tools[tool.name_en] == 'setting_up'"
                          >mdi-autorenew</v-icon
                        >
                      </template>
                      <span class="tooltip_icon_description">設定中</span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-show="item.tools[tool.name_en] == 'updating'"
                      content-class="caption"
                    >
                      <template v-slot:activator="{ on, attrs2 }">
                        <v-icon
                          large
                          mdi
                          v-bind="attrs2"
                          v-on="on"
                          color="success"
                          v-show="item.tools[tool.name_en] == 'updating'"
                          @click="operationgSystemButtonClicked(item, tool)"
                          >mdi-checkbox-marked-circle</v-icon
                        >
                      </template>
                      <span class="tooltip_icon_description"
                        >稼働中<br />クリックでシステムを取り外す</span
                      >
                    </v-tooltip>
                    <v-tooltip bottom v-show="!item.tools[tool.name_en]">
                      <template v-slot:activator="{ on, attrs3 }">
                        <v-icon
                          large
                          mdi
                          v-bind="attrs3"
                          v-on="on"
                          v-show="!item.tools[tool.name_en]"
                          color="#A1A1A1"
                          @click="systemNothingButtonClicked(item, tool)"
                          >mdi-check-circle-outline</v-icon
                        >
                      </template>
                      <span class="tooltip_icon_description"
                        >未稼働<br />クリックでシステムを表示する</span
                      >
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <div class="arrows">
              <span
                class="arrows_btn arrows_btn-left"
                v-show="tableColsCount > 0"
                @click="tableSlide('prev')"
                ><v-icon dark>mdi-chevron-left</v-icon>
              </span>

              <span
                class="arrows_btn arrows_btn-right"
                v-show="tableColsMax - tableCols > tableColsCount"
                @click="tableSlide('next')"
                ><v-icon dark>mdi-chevron-right</v-icon>
              </span>
            </div>
          </v-col>

          <v-overlay v-if="tableLoading" absolute opacity="1" color="#fff">
            <v-progress-circular
              indeterminate
              color="primary"
              size="32"
            ></v-progress-circular>
          </v-overlay>
          <v-overlay v-else-if="itemFetching" absolute opacity="0.25">
            <v-progress-circular
              indeterminate
              color="primary"
              size="32"
            ></v-progress-circular>
          </v-overlay>
        </v-row>

        <table-status
          :checked="checked"
          :pagePers="pagePers"
          :page="page"
          :totalPages="totalPages"
          :total="items.length"
          :totalHits="totalHits"
          :itemsPerPage="itemsPerPage"
          @uncheck_all="uncheckAll()"
          @update:itemsPerPage="
            itemsPerPage = $event;
            fetchItems();
          "
        />

        <v-row justify="center">
          <v-col cols="auto">
            <v-pagination
              v-model="page"
              :length="totalPages"
              @input="changePage()"
              :total-visible="totalVisible"
              circle
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
            ></v-pagination>
          </v-col>
        </v-row>

        <edit-button
          :is_full_auto_on="user[columnNameFullAuto]"
          @checkedItemEditButtonClicked="checkedItemEditButtonClicked()"
          @allItemEditButtonClicked="allItemEditButtonClicked()"
        />
      </div>

      <system-edit-modal
        :checked="checked"
        :is_render="renderBundleEditModal"
        :tools="tools"
        :default_operation="default_system_edit_operation"
        :default_tool="default_system_edit_tool"
        :default_rendering="default_system_edit_rendering"
        :total_items="totalHits"
        :editing_target_is_all_items="editing_target_is_all_items"
        :unfinished_jobs="unfinished_jobs"
        :element="targetElement"
        @close="renderBundleEditModal = false"
        @request_finished="
          systemEditRequestSaved();
          system_edit_finished_alert = true;
        "
      />
      <floating-notification-alert
        :open="system_edit_finished_alert"
        @close="system_edit_finished_alert = false"
      >
        <v-icon color="success">mdi-checkbox-marked</v-icon>
        <span color="success"
          >システム設定を受け付けました。 操作の進捗状況は<router-link
            :to="{ name: 'Dashboard' }"
            >ダッシュボード</router-link
          >のログでご確認ください。
        </span>
      </floating-notification-alert>

      <procedure-index v-if="showProcedureIndex" @close="completeTutorial()" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";

const _ = require("lodash");
import SystemSelect from "./SystemSelect.vue";
import TableFilters from "./TableFilters.vue";
import TableStatus from "./TableStatus.vue";
import ItemTableHead from "./ItemTableHead.vue";
import SystemEditModal from "./SystemEditModal.vue";
import FloatingNotificationAlert from "../../components/alert/FloatingNotificationAlert.vue";
import ToolUtilsMixin from "../../../packs/mixins/tool_utils";
import timeUtilsMixin from "../../../packs/mixins/time_utils";
import FullAutoConfigCard from "./FullAutoConfigCard.vue";
import ProcedureIndex from "../procedure/Index.vue";
import EditButton from "./EditButton.vue";

var array = require("lodash/array");

export default {
  components: {
    SystemSelect,
    TableFilters,
    TableStatus,
    ItemTableHead,
    SystemEditModal,
    FloatingNotificationAlert,
    FullAutoConfigCard,
    ProcedureIndex,
    EditButton,
  },

  mixins: [ToolUtilsMixin, timeUtilsMixin],

  async created() {
    // 商品の取得が完了していない場合は初期設定状況に飛ばす
    if (!this.user.completed_first_update_items) {
      this.$router.push({ name: "InstallationStatus" });
      return;
    }

    if (this.user.items_count > this.maxNumberOfItems) {
      return;
    }

    if (this.$route.query.filter) {
      this.filter = this.$route.query.filter;
      this.searchItems();
    } else if (this.user.plan.code !== "platinum") {
      this.searchItems();
    }

    if (this.$route.query.only_error) {
      this.onlyError = this.$route.query.only_error;
    }
  },

  props: {
    user: { type: Object },
    name: { type: String },
    targetElement: { type: String },
    columnNameFullAuto: { type: String },
    columnNameRecentError: { type: String },
    columnNameRakutenPageLink: { type: String },
    columnNameRemainingByteSize: { type: String },
    exclusionFieldId: { type: Array },
    headers: { type: Object },
  },

  data() {
    return {
      search: "",
      search_shops_tool_id: null,
      filter: "all",
      status: "all",
      onlyError: false,
      onlyChecked: false,
      order: "status",
      direction: "asc",
      items: [],
      tools: [],
      unfinished_jobs: [],
      unfinished_all_items_jobs_tool_ids: [],
      page: 1,
      itemsPerPage: 50,
      totalPages: null,
      totalVisible: 7,
      totalHits: 0,
      checked: [],
      checkedAll: false,
      refreshTimer: 10,
      renderBundleEditModal: false,
      default_system_edit_operation: null,
      default_system_edit_tool: null,
      default_system_edit_rendering: "selection_of_operation",
      editing_target_is_all_items: false,
      system_edit_finished_alert: false,
      pagePers: [50, 100, 200],
      tab: 1,
      tableWidth: "",
      tableCols: 5,
      tableColWidth: "",
      tableColsCount: 0,
      tableLoading: true,
      itemFetching: false,
      timer: 0,
      omakaseImgUrl: `/images/components/help_for_full_auto/${this.targetElement}.png`,
      showTable: false,
      maxNumberOfItems: 500000,
    };
  },

  computed: {
    have_setting_up_items() {
      return this.items.some((item) => {
        return Object.values(item.tools).some((toolConfig) => {
          return toolConfig == "setting_up";
        });
      });
    },
    tools_on_table() {
      if (this.search_shops_tool_id) {
        let shops_tool = this.getShopsToolByShopsToolId(
          this.user.shops_tools,
          this.search_shops_tool_id
        );
        let tool = this.getToolByToolId(this.tools, shops_tool.tool_id);
        return [tool];
      } else {
        return this.tools;
      }
    },
    showProcedureIndex() {
      return (
        !this.user.completed_tutorial && !this.user[this.columnNameFullAuto]
      );
    },
    selectedSystemId() {
      let selected = this.user.shops_tools.find(
        (data) => data.id === this.search_shops_tool_id
      );
      return selected ? selected.tool_id : "";
    },
    tableColsMax() {
      return this.selectedSystemId
        ? [this.tools.find((data) => data.id === this.selectedSystemId)].length
        : this.tools.length;
    },
  },

  watch: {
    checked: function () {
      if (this.onlyChecked) {
        this.fetchItems();
      }
    },
    refreshTimer: async function (timer) {
      if (timer <= 0) {
        await this.fetchUnfinishedJobs();
        await this.setUnfinishedAllItemsJobsToolIds();
        let updating_items = this.items.filter((item) => {
          return Object.values(item.tools).some((toolConfig) => {
            return toolConfig == "setting_up";
          });
        });
        let updating_codes = updating_items.map((item) => {
          return item.item_code;
        });
        let refreshed_items = await this.fetchItemsByCodes(updating_codes);
        refreshed_items.forEach((refreshed_item) => {
          let matched_data_item = this.items.forEach((data_item, i) => {
            if (data_item.item_code == refreshed_item.item_code) {
              this.items.splice(i, 1, refreshed_item);
            }
          });
        });
        await this.mergeSettings();
        this.refreshTimer = 10;
      }
    },
  },

  methods: {
    async searchItems() {
      if (!this.tools.length) {
        await this.fetchTools();
      }
      this.showTable = true;
      if (!this.unfinished_jobs.length) {
        await this.fetchUnfinishedJobs();
        await this.setUnfinishedAllItemsJobsToolIds();
      }

      this.page = 1;
      await this.fetchItems();

      setInterval(() => {
        if (this.have_setting_up_items) {
          this.refreshTimer--;
        }
      }, 1000);

      this.tableCreate();
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.handleResize);
      this.tableLoading = false;
    },

    async systemEditRequestSaved() {
      this.checked = [];
      this.renderBundleEditModal = false;
      this.reload();
    },

    async fetchTools() {
      await axios
        .get("/api/v1/tools", { params: { target: this.targetElement } })
        .then((response) => {
          this.tools = response.data.tools;
        });
    },

    async setUnfinishedAllItemsJobsToolIds() {
      let unfinished_all_items_jobs = this.unfinished_jobs.filter(
        (job) => job.is_all_items
      );
      let is_all_tool_job_exists = unfinished_all_items_jobs.some((job) => {
        return job.is_all_tools;
      });
      let tool_ids;
      if (is_all_tool_job_exists) {
        tool_ids = this.user.shops_tools.map((tool) => {
          return tool["tool_id"];
        });
      } else {
        tool_ids = unfinished_all_items_jobs.map((job) => {
          if (job.shops_tool && job.shops_tool.tool_id) {
            return job.shops_tool.tool_id;
          } else {
            return job.job_target_items_tools[0].shops_tool.tool_id;
          }
        });
      }
      this.unfinished_all_items_jobs_tool_ids = tool_ids;
    },

    async fetchUnfinishedJobs() {
      await axios
        .get("/api/v1/jobs/unfinished_jobs", {
          params: { target_element: this.targetElement },
        })
        .then((response) => {
          this.unfinished_jobs = response.data.unfinished_jobs;
        });
    },

    async fetchItems() {
      this.itemFetching = true;

      var params = {
        page: this.page,
        per: this.itemsPerPage,
        keyword: this.search,
        filter: this.filter,
        status: this.status,
        only_checked: this.onlyChecked,
        only_error: this.onlyError,
        order: this.order,
        shops_tool_id: this.search_shops_tool_id,
        direction: this.direction,
        target: this.targetElement,
      };

      if (this.onlyChecked) {
        params.checked_item_codes = this.checked;
      }

      await axios
        .post("/api/v1/items/search", params, { timeout: 1000 * 60 * 5 })
        .then(async (response) => {
          this.items = response.data.items;
          this.totalPages = response.data.total_pages;
          this.totalHits = response.data.total_hits;
          this.count = response.data.count;
          this.countRunning = response.data.count_running;
          await this.mergeSettings();
          this.checkedAll = this.isCheckedAll();
        });

      this.refreshTimer = 10;
      this.tableColsCount = 0;
      this.tableCreate();
      this.tableSlide();

      this.itemFetching = false;
    },

    async fetchItemsByCodes(item_codes) {
      let res = await axios.post(
        "/api/v1/items/search_by_codes",
        {
          item_codes: item_codes,
          target: this.targetElement,
        },
        { timeout: 1000 * 60 * 5 }
      );
      return res.data.items;
    },

    mergeSettings() {
      this.items.forEach((item, i) => {
        item.tools = [];
        this.tools.forEach((tool) => {
          if (this.unfinished_all_items_jobs_tool_ids.includes(tool.id)) {
            // 全商品が対象の未完了のジョブがあれば設定中
            this.$set(this.items[i].tools, tool.name_en, "setting_up");
          } else {
            var job = item.unfinished_bundle_jobs.find((x) => {
              if (x.target_element !== this.targetElement) {
                return false;
              }
              if (x.is_all_tools) {
                return true;
              } else if (typeof x.shops_tool !== "undefined") {
                return x.shops_tool.tool_id == tool.id;
              } else {
                return x.job_target_items_tools.find((y) => {
                  return y.shops_tool.tool_id == tool.id;
                });
              }
            });

            // 商品毎に未完了のジョブがあれば設定中
            if (typeof job !== "undefined") {
              this.$set(this.items[i].tools, tool.name_en, "setting_up");
            } else {
              var comment_tag_setting = item.comment_tag_settings.find((x) => {
                return (
                  x.target == this.targetElement &&
                  x.shops_tool?.tool_id == tool.id
                );
              });

              // 商品毎にコメントタグ設定があれば更新中
              if (
                typeof comment_tag_setting !== "undefined" &&
                comment_tag_setting.status === "enable"
              ) {
                this.$set(this.items[i].tools, tool.name_en, "updating");
              } else {
                item.tools[tool.name_en] = null;
              }
            }
          }
        });

        item.errors = this.recentErrors(item);
      });
    },

    async reload() {
      await this.$emit("fetchUser");
      await this.fetchUnfinishedJobs();
      await this.setUnfinishedAllItemsJobsToolIds();
      await this.fetchItems();
    },

    isCheckedAll() {
      if (!this.checked.length) {
        return false;
      }

      var set = new Set(this.checked);
      for (let i = 0; i < this.items.length; i++) {
        if (!set.has(this.items[i].item_code)) {
          return false;
        }
      }
      return true;
    },

    sortItems(key) {
      if (key == this.order) {
        this.direction = this.direction == "asc" ? "desc" : "asc";
      } else {
        this.order = key;
        this.direction = "asc";
      }

      this.page = 1;
      this.fetchItems();
    },

    changePage() {
      this.fetchItems();
    },

    checkAll() {
      var set = new Set(this.checked);

      this.items
        .filter((item) => {
          return (
            !this.user[this.columnNameFullAuto] ||
            !item[this.columnNameFullAuto]
          );
        })
        .forEach((item) => {
          if (this.checkedAll) {
            set.add(item.item_code);
          } else {
            set.delete(item.item_code);
          }
        });

      this.checked = Array.from(set);
    },

    uncheckAll() {
      this.checked = [];
      this.checkedAll = this.isCheckedAll();
    },

    operationgSystemButtonClicked(item, tool) {
      this.default_system_edit_operation = "bundle_delete";
      this.checked = [item.item_code];
      this.editing_target_is_all_items = false;
      this.renderBundleEditModal = true;
      this.default_system_edit_tool = tool;
      this.default_system_edit_rendering = "confirm_to_delete";
    },

    systemNothingButtonClicked(item, tool) {
      this.default_system_edit_operation = "bundle_create";
      this.checked = [item.item_code];
      this.editing_target_is_all_items = false;
      this.default_system_edit_rendering = "selection_of_design";
      this.renderBundleEditModal = true;
      this.default_system_edit_tool = tool;
    },
    checkedItemEditButtonClicked() {
      if (this.checked.length == 0) {
        alert("商品をチェックしてください。");
        return;
      }
      this.editing_target_is_all_items = false;
      this.default_system_edit_operation = null;
      this.default_system_edit_tool = null;
      this.default_system_edit_rendering = "selection_of_operation";
      this.renderBundleEditModal = true;
    },
    allItemEditButtonClicked() {
      this.editing_target_is_all_items = true;
      this.default_system_edit_operation = null;
      this.default_system_edit_tool = null;
      this.default_system_edit_rendering = "selection_of_operation";
      this.renderBundleEditModal = true;
    },
    clickRow(row) {
      window.location.href = "/items/" + row.id + "/edit/";
    },

    async updateItemUseOsusumeConf(item_id, value) {
      let res = await axios.patch(`/api/v1/items/${item_id}`, {
        [this.columnNameFullAuto]: value,
      });
    },

    async updateFullAuto(value) {
      await axios
        .post("/api/v1/shops/me", {
          [this.columnNameFullAuto]: value,
        })
        .then((res) => {
          this.$emit("setUser", res.data);
        });
    },

    async completeTutorial() {
      await axios
        .post("/api/v1/shops/me", {
          completed_tutorial: 1,
        })
        .then((res) => {
          this.$emit("setUser", res.data);
        });
    },

    showProcedureOneItem() {
      window.open(
        this.$router.resolve({ name: "ItemProcedureOneItem" }).href,
        "_blank",
        "width=775,height=485"
      );
    },

    showProcedureMultipleItems() {
      window.open(
        this.$router.resolve({ name: "ItemProcedureMultipleItems" }).href,
        "_blank",
        "width=775,height=485"
      );
    },

    tableCreate() {
      this.tableWidth = document.getElementById("tableSlider").clientWidth;
      this.tableColWidth = Math.ceil(this.tableWidth / this.tableCols);

      Array.from(
        document.getElementById("tableSlider").getElementsByTagName("th"),
        (element, index) => {
          element.style.width = this.tableColWidth + "px";
        }
      );
      Array.from(
        document.getElementById("tableSlider").getElementsByTagName("td"),
        (element, index) => {
          element.style.width = this.tableColWidth + "px";
        }
      );

      // theadにwidth付与
      Array.from(
        document.getElementById("tableArea").getElementsByTagName("thead"),
        (element, index) => {
          element.style.width = this.tableWidth + "px";
        }
      );
    },

    tableSlide(action) {
      if (action === "next") {
        // next
        if (this.tableColsMax - this.tableCols > this.tableColsCount) {
          this.tableColsCount++;
        }
      } else if (action === "prev") {
        // prev
        if (this.tableColsCount > 0) {
          this.tableColsCount--;
        }
      }
      Array.from(
        document.getElementById("tableSlider").getElementsByTagName("tr"),
        (element) => {
          element.style.transform =
            "translate(-" +
            this.tableColWidth * this.tableColsCount +
            "px, 0px)";
        }
      );
    },

    handleResize() {
      if (this.timer > 0) {
        clearTimeout(this.timer);
      }
      const self = this;
      this.timer = setTimeout(function () {
        self.tableCreate();
        self.tableSlide();
        self.handleScroll("resize");
      }, 200);
    },

    handleScroll(e) {
      var top = document
        .getElementById("tableSlider")
        .getBoundingClientRect().top;
      var table = document.getElementById("tableArea");

      if (
        (top < 1 && !table.classList.contains("fixed")) ||
        (top < 1 && e === "resize")
      ) {
        table.classList.add("fixed");

        var offset =
          window.innerWidth -
          document.getElementById("tableSlider").getBoundingClientRect().right;
        document.querySelector("#tableArea .arrows_btn-right").style.transform =
          "translate(-" + offset + "px, 0px)";
      } else if (top > 1 && table.classList.contains("fixed")) {
        table.classList.remove("fixed");
        document.querySelector("#tableArea .arrows_btn-right").style.transform =
          "translate(0px, 0px)";
      }
    },

    rakutenPageLink(item) {
      return item[this.columnNameRakutenPageLink];
    },

    remainingByteSize(item) {
      return item[this.columnNameRemainingByteSize];
    },

    recentErrors(item) {
      let job_error = item.job_errors.find((x) => {
        return x.target_element === this.targetElement;
      });
      if (job_error) {
        if (job_error.target === "ecdb_server") {
          // ECDBで検出したエラー
          return [{ msg: job_error.code_jp }];
        } else {
          // 楽天のエラー
          return this.rakutenErrors(job_error);
        }
      } else {
        job_error = item.job_errors.find((x) => {
          return x.target_element === null;
        });
        if (job_error) {
          // 楽天のエラー
          return this.rakutenErrors(job_error);
        } else {
          // なし
          return [];
        }
      }
    },

    rakutenErrors(job_error) {
      let errors = [];
      if (job_error.rakuten_result_json) {
        errors = job_error.rakuten_result_json.map((x) => x.message);
      }
      return errors;
    },
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #f7f7f7 !important;
}

p {
  font-size: 2em;
  text-align: center;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 730px;
  margin: 20px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
  height: auto;
  max-height: 600px;
  vertical-align: middle;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

<style scoped>
.tooltip_item_name {
  font-size: 0.8rem;
}
.tooltip_icon_description {
  font-size: 0.8rem;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.item_sync_icon {
  animation-name: spin;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3360deg);
  }
}

#item_index_precedure_carousel {
  border: 1px solid;
}

.block_item {
  display: inline-flex;
  vertical-align: middle;
}

td.ecup_system_td.full_auto_on {
  pointer-events: none;
  opacity: 0.3;
}
</style>
<style>
#tableArea .scroll_item table {
  position: relative;
  padding-top: 90px;
}

#tableArea .scroll_item table thead {
  position: absolute;
  top: 0;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 1;
}

#tableArea .scroll_item table tr {
  position: relative;
  display: flex;
  transition: all 0.1s ease-out;
}

#tableArea .scroll_item table th {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: none;
}
#tableArea .scroll_item table th.itemName {
  width: 42%;
}
#tableArea .scroll_item table th.remainingBytes,
#tableArea .scroll_item table th.remainingImages,
#tableArea .scroll_item table th.omakase {
  width: 24%;
}
#tableArea .scroll_item table td {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 0;
}

#tableSlider .v-data-table__wrapper {
  position: relative;
  overflow-x: hidden;
}
#tableSlider .v-data-table__wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.12);
}
#tableSlider table {
  table-layout: fixed;
  position: relative;
  left: 0;
  width: auto;
}
#tableSlider table thead tr {
  flex-flow: column wrap;
  align-content: flex-start;
}
#tableSlider table th {
  flex-direction: column;
}
#tableSlider table td:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.arrows {
  position: absolute;
  top: 0;
  display: block;
  width: calc(100% + 30px);
  background: red;
  margin: 0 -15px;
}
.arrows_btn {
  position: absolute;
  top: 28px;
  padding: 4px;
  background: #999;
  border-radius: 50%;
  opacity: 0.8;
  z-index: 2;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out;
}
.arrows_btn:hover {
  background: #2a8cc7;
  opacity: 1;
}
.arrows_btn-left {
  left: 0;
}
.arrows_btn-right {
  right: 0;
}

#tableArea.fixed .scroll_item table thead {
  position: fixed;
}
#tableArea.fixed .arrows_btn {
  position: fixed;
}
#tableArea.fixed .arrows_btn-left {
  left: auto;
}
#tableArea.fixed .arrows_btn-right {
  right: -15px;
}
</style>
