<template>
  <v-row>
    <v-col>
      <v-btn
        large
        block
        disabled
        text
        height="60px"
        v-if="user.plan.code === plan.code"
      >
        <v-icon left> mdi-check </v-icon>
        現在のプランです
      </v-btn>

      <v-btn
        large
        block
        disabled
        height="60px"
        v-else-if="exceeded_item_limit_in(plan.max_num_of_items)"
      >
        商品数超過のため<br />ご利用いただけません
      </v-btn>

      <v-btn
        large
        block
        color="primary"
        height="60px"
        @click="pushChangePage(plan)"
        v-else
      >
        {{ plan.name }}プランに<br />変更する
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Btn",

  props: ["plan"],

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    exceeded_item_limit_in(max_num_of_items) {
      return max_num_of_items < this.user.items_count;
    },

    pushChangePage(plan) {
      this.$emit("push-change-page", plan);
    },
  },
};
</script>
