export default Object.freeze(
    {
        HREF_FAQ: "https://ec-up.zendesk.com/hc/ja",
        HREF_FEEDBACK: "https://ec-up.zendesk.com/hc/ja/requests/new",
    }
)

export const SHOP_PAGE_PART = {
    NEW: {
        ITEM: 101,
        SMALL_IMAGE: 103,
        LARGE_IMAGE: 105,
    },
    RESALE: {
        ITEM: 102,
        SMALL_IMAGE: 104,
        LARGE_IMAGE: 106
    }
}

export const PAGE_TYPE_TOP = 1;
export const PAGE_TYPE_CONTENTS = 2;

export const PLAN_ID = {
    FREE: 1,
    SILVER: 2,
    GOLD: 3,
    TRIAL: 4,
    PLATINUM: 5,
    GOLD_TRIAL: 6,
    SILVER_CONTENTS: 7
};

export const TRIAL_PERIOD_DAYS = 60;